<template>
  <div>
    <div>
      <el-form :inline="true" class="search">
        <el-form-item label="用户名">
          <el-input v-model="searchData.nickname" placeholder="微信名搜索"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.account" placeholder="手机号搜索"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getMembers()">查询</el-button>
        </el-form-item>
      </el-form>
    </div>

    <!----- 表格 start------->
    <el-table :data="tableData" :border="true" empty-text="暂无数据" style="width: 100%">
      <el-table-column prop="id" label="ID"/>
      <el-table-column label="用户">
        <template #default="scope">
          <el-avatar :src="scope.row.avatar"></el-avatar>
          {{ scope.row.nickname }}
        </template>
      </el-table-column>
      <el-table-column prop="account" label="提现账号"/>
      <el-table-column prop="balance" label="余额"/>
      <el-table-column prop="code" label="分享码"/>
      <el-table-column prop="created_at" label="注册时间"/>
    </el-table>

    <!----- 表格 end------->

    <!----- 分页 start------->
    <div class="cherry-page" v-show="pageData.total">
      <el-pagination
          small
          background
          layout="prev, pager, next, total"
          :total="pageData.total"
          v-model:current-page="searchData.page"
          :page-size="searchData.per_page"
          @update:current-page="getMembers()"
          class="mt-4"
      />
    </div>
    <!----- 分页 end------->

  </div>
</template>

<script>
import { apiGetMembers } from '@/api/memberApi'
import {getCompanyId} from "@/plugins/company"

export default {
  created() {
    this.getMembers()
  },
  data() {
    return {
      searchData:{
        nickname: '',
        account: '',
        status: 1,
        page: 1,
        per_page: 15,
        company_id: getCompanyId(),
      },
      pageData:{
        total: 0
      },
      projects:[],
      tableData:[],
    }
  },
  methods:{
    getMembers () {
      apiGetMembers(this.searchData).then(res =>{
        this.pageData = res.meta
        this.tableData = res.data
      })
    }
  }
}
</script>
