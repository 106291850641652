import {httpGet} from '@/plugins/request'

/**
 * 获取用户列表
 * @param query
 * @returns {Promise<*>}
 */
export function apiGetMembers(query = [])
{
    return httpGet('/manage/member', query)
}

